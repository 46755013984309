<template>
<v-card
  :loading="saving"
  class="mt-2"
  flat
  tile
>
  <card-toolbar
    @add-data="openCrudDialog()"
    button-mode
    class="px-0 mb-2"
    include-add
    title="Comments"
  />

  <template slot="progress">
    <v-progress-linear color="success" indeterminate />
  </template>

  <v-sheet
    v-if="comments.length > 0"
    class="px-4"
  >
    <comment-item
      v-for="(comment, i) in comments" :key="comment.comment_id"
      :data="comment"
      :index="comments.length - i"
      :topItem="i == 0"
    />
  </v-sheet>
  <v-alert
    v-else
    :icon="INFO_ICON"
    :type="INFO"
    outlined
    text
  >There are no comments.</v-alert>

  <dialog-crud
    :config="dialog[CRUD].config"
    :dialog.sync="dialog[CRUD].show"
    :payload="payload"
    :saving="saving"
    @save-data="saveWrapper(dialog[CRUD].config.type, payload)"
  >
    <template v-slot:after-fields>
      <v-alert
        :icon="WARNING_ICON"
        :type="WARNING"
        class="mt-6"
        outlined
        text
        prominent
      >Please review the comment carefully, as it cannot be modified or deleted once saved.</v-alert>
    </template>
  </dialog-crud>
</v-card>
</template>

<script>
import { cloneDeep, forEach } from 'lodash'

import { COLOR, DIALOGS, ICONS, SELECT, } from '../../constants'

export default {
  components: {
    CardToolbar: () => import('./toolbars/CardToolbar.vue'),
    CommentItem: () => import('./CommentItem.vue'),
    DialogCrud: () => import('./dialog/DialogCrud.vue'),
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    createFunction: {
      type: Function,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    // Dialog window state.
    dialog: {
      [DIALOGS.CRUD]: { show: false, config: {}, payload: {}, },
    },
  }),
  computed: {
    payload () {
      return this.dialog[DIALOGS.CRUD].payload
    },
  },
  methods: {
    /**
     * Close and reset a dialog window.
     * @param {string} type Dialog window type.
     */
    dialogClose (type) {
      this.dialog[type] = { show: false, config: {}, payload: {}, }
    },
    /**
     * Initialize any constants that are used within the template.
     */
    initTemplateConstants () {
      this.CRUD = DIALOGS.CRUD
      
      this.INFO_ICON = ICONS.INFO
      this.WARNING_ICON = ICONS.WARNING

      this.INFO = COLOR.INFO
      this.WARNING = COLOR.WARNING

      this.SELECT = SELECT
    },
    /**
     * Initializes and opens the dialog window for this data-type with default values set for the fields.
     */
    openCrudDialog () {
      this.dialog[DIALOGS.CRUD].config = {
        fields: {
          fieldGroups: cloneDeep(this.model.getFieldGroups(this.type)),
        },
        title: 'Add Comment',
        type: this.type,
      }
      this.dialog[DIALOGS.CRUD].payload = cloneDeep(this.model.getDefaultItem(this.type))

      // Set any selects to its first item.
      forEach(this.dialog[DIALOGS.CRUD].config.fields.fieldGroups, fieldGroup => {
        forEach(fieldGroup, field => {
          if (field.fieldType === SELECT) {
            this.dialog[DIALOGS.CRUD].payload[field.property] = field.items[0].comment_type
          }
        })
      })

      delete this.dialog[DIALOGS.CRUD].payload.default

      this.dialog[DIALOGS.CRUD].show = true
    },
    saveWrapper(type, item) {
      this.createFunction(type, item)
      this.dialogClose(DIALOGS.CRUD)
    },
  },
  filters: {
    /**
     * Formats an input date string into "YYYY-MM-DD, HH24:Mi:SS".
     */
    simpleDate: function (value) {
      return value.split('T').join(', ')
    }
  },
  created () {
    this.initTemplateConstants()
  }
}
</script>